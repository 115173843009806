import 'leaflet/dist/leaflet.css';

import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { endOfWeek, startOfWeek } from 'date-fns';
import { format } from 'date-fns-tz';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderSearchConditionEntity } from 'src/entities/OrderSearchCondition.entity';
import { PlanningEntity, RespectType, WeekDayEn } from 'src/entities/planningEntity';
import { PlanningConditions, PlanningSettingRequest } from 'src/entities/PlanningHistory.entity';
import { AsyncPlanningResponseEntity, PlanningResponseEntity } from 'src/entities/planningResponseEntity';
import {
  BaseOperationEntity,
  distanceMmBurdenPerShippersEntity,
  PlanningsDeliveryEntity
} from 'src/entities/PlanningsDelivery.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsMapGaragePositionEntity } from 'src/entities/PlanningsMapGaragePosition.entity';
import { PlanningsMapOperationPositionEntity } from 'src/entities/PlanningsMapOperationPosition.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { useGaragesQuery } from 'src/hooks/useGarages.query';
import { usePastOperationsQuery } from 'src/hooks/usePastOperations.request.query';
import { useMutationShiftClone } from 'src/hooks/useQueryDriverShifts';
import { useQueryLastOperationStatusUpdated } from 'src/hooks/useQueryLastOperationStatusUpdated';
import { useQueryLatestAlgorithmRequestVersion } from 'src/hooks/useQueryLatestAlgorithmRequestVersion';
import { useInfiniteQueryUnallocatedOrders, useMutationOrder } from 'src/hooks/useQueryOrders';
import { useMutationPlanning, useScenarioPlanning } from 'src/hooks/useQueryPlanning';
import { useQueryPlanningGroups } from 'src/hooks/useQueryPlanningGroups';
import { useQueryPlanningMapUnallocatedOrderPositions } from 'src/hooks/useQueryPlanningMapUnallocatedOrderPositions';
import { useQueryPlanningOrderStatistics } from 'src/hooks/useQueryPlanningOrderStatistics';
import { useQueryPlanningsDeliveries } from 'src/hooks/useQueryPlanningsDeliveries';
import { useQueryPlanningsDrivers } from 'src/hooks/useQueryPlanningsDrivers';
import { useQueryPlanningsNotAllocReasons } from 'src/hooks/useQueryPlanningsNotAllocReasons';
import { useMutationPlanningOperations } from 'src/hooks/useQueryPlanningsOperations';
import { useQueryPlanningsResults } from 'src/hooks/useQueryPlanningsResults';
import { useMutationPlanningRunnings, useQueryPlanningsRunnings } from 'src/hooks/useQueryPlanningsRunnings';
import { useQueryPlanningsTrucks } from 'src/hooks/useQueryPlanningsTrucks';
import { Operations } from 'src/models/Operations';
import { PlanningsOperationDelivery, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import arrayUtil from 'src/utils/array.util';
import { RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';
import { TruckDisplayStatusVo } from 'src/vo/TruckDisplayStatusVo';

import Presenter from './Presenter';
import {
  allocateHistoriesReducer, calculatingReducer, currentHistoryVersionReducer,
  deliveryEntitiesReducer,
  displayOrderIdReducer, driverEntitiesReducer, editPlacesReducer, fitBoundsPositionsReducer,
  flyToPositionReducer,
  groupEntitiesReducer,
  importFileReducer,
  importForUpdateFileReducer,
  isLoadingReducer,
  lastOperationStatusUpdatedAtReducer,
  latestAlgorithmRequestVersionReducer,
  notAllocReasonsReducer,
  notAllocReasonStateReducer,
  operationEntitiesReducer,
  orderEntityMapReducer,
  orderIdsReducer,
  planningInitialState,
  planningMapUnallocatedOrderPositionEntitiesReducer,
  planningReducer,
  planningsMapGaragePositionEntitiesReducer,
  planningsMapOperationPositionEntitiesReducer,
  planningsOperationDeliveryByDeliveryIdEntityReducer,
  planningsOperationEntitiesWithStatsByDeliveryIdEntityReducer,
  selectedCycleIndexesReducer,
  selectedGroupEntitiesReducer,
  selectedOrderIdsReducer, transferRequestReducer, truckEntitiesReducer
} from './reducers';

type Props = {
  startOn: string;
  endOn: string;
  unit: string;
  driverCostYenPerHours: number | undefined;
  truckFuelCostYenPerKm: number | undefined;
  truckInsuranceFeeYenPerDay: number | undefined;
  truckRepairCostYenPerDay: number | undefined;
  truckExpresswayFeeYenPerShift: number | undefined;
  customInputFields: string[];
  activateConstraintLoadAfterNotCleared: boolean;
}

const Component: FC<Props> = memo((
  {
    startOn,
    endOn,
    unit,
    driverCostYenPerHours,
    truckFuelCostYenPerKm,
    truckInsuranceFeeYenPerDay,
    truckRepairCostYenPerDay,
    truckExpresswayFeeYenPerShift,
    customInputFields,
    activateConstraintLoadAfterNotCleared,
  }
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const generateRandomString = (charCount = 7): string => {
    const str = Math.random().toString(36).substring(2).slice(-charCount);
    return str.length < charCount ? str + 'a'.repeat(charCount - str.length) : str;
  };

  const licenseContext = useContext(LicenseContext);

  const reset = useCallback(() => {
    dispatchPlanning({
      type: 'updateStartOn',
      payload: startOn
    });
    dispatchPlanning({
      type: 'updateEndOn',
      payload: endOn
    });
    dispatchOrderIds({ type: 'reset' });
    dispatchSelectedOrderIds({ type: 'reset' });
    dispatchTransfer({ type: 'reset' });
    dispatchImportFile({ type: 'reset' });
    dispatchSelectedCycleIndexes({ type: 'reset' });
    dispatchSelectedGroupEntities({ type: 'reset' });

    queryClient.clear();
  }, [startOn, endOn, queryClient]);

  // queryの前にないと、query が2回呼ばれてしまう。
  useEffect(() => {
    if (!licenseContext.config?.selected_company_id) return;
    reset();
  }, [startOn, endOn, licenseContext.config?.selected_company_id, reset]);

  const orderEntitiesInfiniteQuery = useInfiniteQueryUnallocatedOrders(startOn, endOn);

  const { data: garageData } = useGaragesQuery();
  const { data: truckData, isLoading: trucksIsLoading } = useQueryPlanningsTrucks(startOn, endOn);
  const { data: driverData, isLoading: driversIsLoading } = useQueryPlanningsDrivers(startOn, endOn);
  const { data: deliveryData, isLoading: deliveriesIsLoading } = useQueryPlanningsDeliveries(startOn, endOn);
  const { data: planningMapUnallocatedOrderPositionsData } = useQueryPlanningMapUnallocatedOrderPositions(startOn, endOn);
  const { data: planningGroupsData } = useQueryPlanningGroups();
  const { data: planningRunningEntities } = useQueryPlanningsRunnings(startOn, endOn);

  const [truckEntities, dispatchTruckEntities] = useReducer(truckEntitiesReducer, []);
  const [driverEntities, dispatchDriverEntities] = useReducer(driverEntitiesReducer, []);
  const [deliveryEntities, dispatchDeliveryEntities] = useReducer(deliveryEntitiesReducer, []);
  const [operationEntities, dispatchOperationEntities] = useReducer(operationEntitiesReducer, []);
  const [groupEntities, dispatchGroupEntities] = useReducer(groupEntitiesReducer, []);
  const [planningsMapGaragePositionEntities, dispatchPlanningsMapGaragePositionEntities] = useReducer(planningsMapGaragePositionEntitiesReducer, []);
  const [planningsMapOperationPositionEntities, dispatchPlanningsMapOperationPositionEntities] = useReducer(planningsMapOperationPositionEntitiesReducer, []);
  const [planningsOperationEntitiesWithStatsByDeliveryIdEntity, dispatchPlanningsOperationEntitiesWithStatsByDeliveryIdEntity] = useReducer(planningsOperationEntitiesWithStatsByDeliveryIdEntityReducer, {});
  const [planningsOperationDeliveryByDeliveryIdEntity, dispatchPlanningsOperationDeliveryByDeliveryIdEntity] = useReducer(planningsOperationDeliveryByDeliveryIdEntityReducer, undefined);
  const [editPlaces, dispatchEditPlaces] = useReducer(editPlacesReducer, []);
  const [lastOperationStatusUpdated, dispatchLastOperationStatusUpdated] = useReducer(lastOperationStatusUpdatedAtReducer, null);
  const { data: lastOperationStatusUpdatedData } = useQueryLastOperationStatusUpdated(startOn, endOn, deliveryEntities.map((it) => it.id));
  const { data: latestAlgorithmRequestVersionData } = useQueryLatestAlgorithmRequestVersion(startOn, endOn);
  const [latestAlgorithmRequestVersion, dispatchLatestAlgorithmRequestVersion] = useReducer(latestAlgorithmRequestVersionReducer, null);
  const [flyToPosition, dispatchFlyToPosition] = useReducer(flyToPositionReducer, undefined);
  const [fitBoundsPositions, dispatchFitBoundsPositions] = useReducer(fitBoundsPositionsReducer, []);
  const [allocateHistories, dispatchAllocateHistories] = useReducer(allocateHistoriesReducer, []);
  const [currentHistoryVersion, dispatchCurrentHistoryVersion] = useReducer(currentHistoryVersionReducer, undefined);
  const [latestAllocateHistory, setLatestAllocateHistory] = useState<AllocateHistoryEntity | undefined>(undefined);
  const [directionSettingDialogIsOpen, setDirectionSettingDialogIsOpen] = useState<boolean>(false);
  const updateDirectionSettingDialogIsOpen = useCallback((val: boolean) => {
    setDirectionSettingDialogIsOpen(val);
  }, []);

  const [asyncPlanningResponse, setAsyncPlanningResponse] = useState<AsyncPlanningResponseEntity>(null);
  const { data: planningResultData, error: planningResultError } = useQueryPlanningsResults(asyncPlanningResponse);

  const { data: pastOperationsData } = usePastOperationsQuery(startOn);

  const setCurrentHistoryVersion = useCallback((version: string) => {
    dispatchCurrentHistoryVersion({
      type: 'set',
      payload: version,
    });
  }, []);

  const resetAllocateHistories = useCallback(() => {
    dispatchAllocateHistories({
      type: 'reset'
    });
  }, []);

  const resetCurrentHistoryVersion = useCallback(() => {
    dispatchCurrentHistoryVersion({
      type: 'reset'
    });
    dispatchDeliveryEntities({
      type: 'set',
      payload: deliveryData || []
    });
  }, [deliveryData]);

  useEffect(() => {
    if (!currentHistoryVersion) return;

    const historyVersionDeliveries = allocateHistories
      .find(({ version }) => version === currentHistoryVersion)
      ?.deliveryEntities;

    if (!historyVersionDeliveries) return;

    dispatchDeliveryEntities({
      type: 'set',
      payload: historyVersionDeliveries
    });
  }, [allocateHistories, currentHistoryVersion]);

  const resetFitBoundsPositions = useCallback(() => {
    dispatchFitBoundsPositions({
      type: 'reset'
    });
  }, []);

  const resetFlyToPosition = useCallback(() => {
    dispatchFlyToPosition({ type: 'reset' });
  }, []);

  const setPlanningsOperationDeliveryByDeliveryIdEntity = useCallback((entity: PlanningsOperationDeliveryByDeliveryIdEntity) => {
    dispatchPlanningsOperationDeliveryByDeliveryIdEntity({
      type: 'set',
      payload: entity,
    });
  }, []);

  const addEmptyCycle = useCallback((deliveryId: number) => {
    dispatchPlanningsOperationDeliveryByDeliveryIdEntity({
      type: 'addEmptyCycle',
      payload: deliveryId,
    });
  }, []);

  const removeEmptyCycle = useCallback((deliveryId: number, cycleIndex: number) => {
    dispatchPlanningsOperationDeliveryByDeliveryIdEntity({
      type: 'removeEmptyCycle',
      payload: { deliveryId, cycleIndex },
    });
  }, []);

  const updateEditPlaces = useCallback((deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => {
    dispatchEditPlaces({
      type: 'set',
      payload: {
        deliveryId,
        cycleIndex,
        places,
      }
    });
  }, []);

  const resetEditPlaces = useCallback(() => {
    dispatchEditPlaces({
      type: 'reset',
    });
  }, []);

  useEffect(() => {
    if (!operationEntities) return;
    if (!licenseContext.config) return;

    const model = new Operations(licenseContext.config, operationEntities);

    dispatchPlanningsOperationEntitiesWithStatsByDeliveryIdEntity({
      type: 'set',
      payload: model.groupAndSortEntitiesByDeliveryIdWithVolumeAndWeightIdx()
    });

    const tmp = model.createPlanningsOperationDeliveryByDeliveryIdEntity();
    const entity: PlanningsOperationDeliveryByDeliveryIdEntity = deliveryEntities.reduce((acc, delivery) => {
      acc[delivery.id] = tmp[delivery.id] ?? PlanningsOperationDelivery.empty(licenseContext.config?.delivery_list_grouping);
      return acc;
    }, {} as PlanningsOperationDeliveryByDeliveryIdEntity);

    setPlanningsOperationDeliveryByDeliveryIdEntity(entity);
  }, [operationEntities, licenseContext.config, deliveryEntities, setPlanningsOperationDeliveryByDeliveryIdEntity]);

  useEffect(() => {
    if (!deliveryEntities) return;
    if (!garageData) return;

    const groupDeliveriesByGarage = (deliveries: PlanningsDeliveryEntity[]): PlanningsMapGaragePositionEntity[] => {
      const garageMap = new Map<string, PlanningsMapGaragePositionEntity>();

      for (let i = 0; i < deliveries.length; i++) {
        const delivery = deliveries[i];
        const garageKey = `${delivery.garageLatitude},${delivery.garageLongitude}`;
        let garagePosition = garageMap.get(garageKey);

        if (!garagePosition) {
          const garage = garageData.find((it) => it.latitude === delivery.garageLatitude && it.longitude === delivery.garageLongitude);
          garagePosition = {
            garageEntity: garage,
            position: [parseFloat(delivery.garageLatitude), parseFloat(delivery.garageLongitude)],
            deliveryIds: []
          };
          garageMap.set(garageKey, garagePosition);
        }

        garagePosition.deliveryIds.push(delivery.id);
      }

      return Array.from(garageMap.values());
    };

    dispatchPlanningsMapGaragePositionEntities({
      type: 'set',
      payload: groupDeliveriesByGarage(deliveryEntities)
    });
  }, [deliveryEntities, garageData]);

  useEffect(() => {
    if (!operationEntities) return;

    const groupOperationsByDelivery = (operations: PlanningsOperationEntity[]): PlanningsMapOperationPositionEntity[] => {
      const deliveryMap = new Map<string, PlanningsMapOperationPositionEntity>();

      for (let i = 0; i < operations.length; i++) {
        const operation = operations[i];
        const garageKey = `${operation.latitude},${operation.longitude}`;
        let garagePosition = deliveryMap.get(garageKey);

        if (!garagePosition) {
          garagePosition = {
            position: [parseFloat(operation.latitude), parseFloat(operation.longitude)] as PositionEntity,
            deliveryIds: []
          };
          deliveryMap.set(garageKey, garagePosition);
        }

        garagePosition.deliveryIds.push(operation.shiftTruckId);
      }

      return Array.from(deliveryMap.values());
    };

    dispatchPlanningsMapOperationPositionEntities({
      type: 'set',
      payload: groupOperationsByDelivery(operationEntities)
    });
  }, [operationEntities]);

  const [orderSearchKw, setOrderSearchKw] = useState<string>('');
  const [currentlyOrderSearching, setCurrentlyOrderSearching] = useState<boolean>(false);
  const [orderSearchConditions, setOrderSearchConditions] = useState<OrderSearchConditionEntity[]>([]);

  const [truckDisplayStatus, setTruckDisplayStatus] = useState<TruckDisplayStatusVo>('すべて表示');
  const [selectedGroupEntities, dispatchSelectedGroupEntities] = useReducer(selectedGroupEntitiesReducer, undefined);

  const updateSelectedGroupEntities = useCallback((entities: PlanningsGroupEntity[] | undefined) => {
    dispatchSelectedGroupEntities({
      type: 'set',
      payload: entities
    });
  }, []);

  useEffect(() => {
    if (!planningGroupsData || !truckData) return;

    const allTrucks: PlanningsGroupEntity = {
      id: 0,
      name: 'すべて表示する',
      truckIds: truckData.map((it) => it.id),
    };
    dispatchGroupEntities({
      type: 'set',
      payload: [allTrucks, ...planningGroupsData]
    });
  }, [planningGroupsData, truckData]);

  const [planningMapUnallocatedOrderPositionEntities, dispatchPlanningMapUnallocatedOrderPositionEntities] = useReducer(planningMapUnallocatedOrderPositionEntitiesReducer, []);
  useEffect(() => {
    if (!planningMapUnallocatedOrderPositionsData) return;

    dispatchPlanningMapUnallocatedOrderPositionEntities({
      type: 'set',
      payload: planningMapUnallocatedOrderPositionsData
    });
  }, [planningMapUnallocatedOrderPositionsData]);

  const [calculating, dispatchCalculating] = useReducer(calculatingReducer, false);
  const setCalculating = useCallback((val: boolean) => {
    dispatchCalculating({
      type: 'set',
      payload: val
    });
  }, []);

  const [displayOrderId, dispatchDisplayOrderId] = useReducer(displayOrderIdReducer, undefined);
  const updateDisplayOrderId: (orderId: number) => void = useCallback(
    (orderId) => {
      dispatchDisplayOrderId({
        type: 'update',
        payload: orderId
      });
    },
    [],
  );

  const resetDisplayOrderId: () => void = useCallback(
    () => {
      dispatchDisplayOrderId({
        type: 'reset'
      });
    },
    [],
  );

  const { scenarioPlanning } = useScenarioPlanning();
  const { normalPlanning, transferPlanning, swapPlanning, rollback } = useMutationPlanning();
  const { operationBulkResetting, deleteOrders, addOrder, restoreSplittedOrder } = useMutationOrder();
  const { delete: deletePlanningRunning } = useMutationPlanningRunnings();
  const [isLoading, dispatchIsLoading] = useReducer(isLoadingReducer, false);
  const [selectedCycleIndexes, dispatchSelectedCycleIndexes] = useReducer(selectedCycleIndexesReducer, []);

  const updateSelectedCycleIndexes = useCallback((deliveryId: number, cycleIndexes: number[]) => {
    if (cycleIndexes.length === 0) {
      dispatchSelectedCycleIndexes({ type: 'remove', payload: deliveryId });
      return;
    }
    dispatchSelectedCycleIndexes({
      type: 'update',
      payload: { deliveryId, cycleIndexes },
    });
  }, []);

  const resetQuery = useCallback(() => {
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningsDeliveries']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningOrderStatistics']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['planningMapUnallocatedOrderPositions', { startOn, endOn }]
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['planningMapPositions', { startOn, endOn }]
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useDeliveriesQuery']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningsNotAllocReasons']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryLatestAlgorithmRequestVersion']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['orders', startOn, endOn]
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningScenario']
    );
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningSettings']
    );
  }, [endOn, queryClient, startOn]);

  const refreshRunningEntities = useCallback(() => {
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(
      ['useQueryPlanningsRunnings']
    );
  }, [queryClient]);

  const cancelRunningPlan = useCallback((id: number) => {
    deletePlanningRunning.mutate({ id, startOn, endOn }, {
      onSuccess: () => {
        enqueueSnackbar('実行中の自動配車を停止しました。');
      },
      onError: (_) => {
        enqueueSnackbar('自動配車の停止処理でエラーが発生しました。');
      },
      onSettled: () => {
        setAsyncPlanningResponse(null);
        queryClient.clear();
        dispatchIsLoading({
          type: 'notLoading'
        });
        setCalculating(false);
      }
    });
  }, [deletePlanningRunning, endOn, enqueueSnackbar, queryClient, setCalculating, startOn]);

  const [orderIds, dispatchOrderIds] = useReducer(orderIdsReducer, []);
  const [orderEntityMap, dispatchOrderEntityMap] = useReducer(orderEntityMapReducer, null);

  const [selectedOrderIds, dispatchSelectedOrderIds] = useReducer(selectedOrderIdsReducer, []);
  const addSelectedOrderId = useCallback(
    (id: number) => {
      dispatchSelectedOrderIds({
        type: 'add',
        payload: id
      });
    },
    [],
  );
  const removeSelectedOrderId = useCallback(
    (id: number) => {
      dispatchSelectedOrderIds({
        type: 'remove',
        payload: id
      });
    },
    [],
  );

  const mutateRestoreSplittedOrder: (orderId: number) => void = useCallback((orderId) => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('分割した案件を復元して未割り当てにします。よろしいですか？')) return;

    dispatchIsLoading({ type: 'loading' });
    restoreSplittedOrder.mutate(
      orderId,
      {
        onSuccess: () => {
          resetQuery();
          enqueueSnackbar('分割した案件をもとに戻しました。');
        },
        onError: () => {
          enqueueSnackbar('分割した案件を元に戻す処理でエラーが発生しました。');
        },
        onSettled: () => {
          dispatchIsLoading({ type: 'notLoading' });
        }
      }
    );
  }, [enqueueSnackbar, resetQuery, restoreSplittedOrder]);

  const mutateDeleteOrder: () => void = useCallback(() => {
    if (!selectedOrderIds) return;
    if (!selectedOrderIds.length) return;

    enqueueSnackbar(`${selectedOrderIds.length.toLocaleString()}件の案件を削除します`);
    dispatchIsLoading({ type: 'loading' });

    deleteOrders.mutate(
      selectedOrderIds,
      {
        onSuccess: () => {
          dispatchSelectedOrderIds({
            type: 'bulkRemove',
            payload: selectedOrderIds
          });

          dispatchOrderIds({
            type: 'remove',
            payload: selectedOrderIds
          });

          resetQuery();
          enqueueSnackbar(`${selectedOrderIds.length.toLocaleString()}件の案件を削除しました`);
        },
        onError: () => {
          enqueueSnackbar('案件の削除でエラーが発生しました。');
        },
        onSettled: () => {
          dispatchIsLoading({ type: 'notLoading' });
        }
      }
    );
  }, [enqueueSnackbar, resetQuery, selectedOrderIds]);

  const mutateDeleteSpecificOrder: (orderId: number) => void = useCallback((orderId) => {
    dispatchIsLoading({ type: 'loading' });

    deleteOrders.mutate([orderId]);
    dispatchSelectedOrderIds({
      type: 'remove',
      payload: orderId
    });

    dispatchOrderIds({
      type: 'remove',
      payload: [orderId]
    });

    resetQuery();

    dispatchIsLoading({ type: 'notLoading' });
  }, [resetQuery]);

  const mutateCloneOrder: (order: OrderEntity) => void = useCallback((order) => {
    dispatchIsLoading({ type: 'loading' });

    order.id = null;
    order.uuid = null;
    // eslint-disable-next-line no-void
    void addOrder.mutateAsync(order).finally(() => {
      // src/components/V2OrderForm/index.tsx の reset を真似ている
      const resetQueryKeys = ['shifts', 'shift', 'unallocatedOrders', 'order', 'shippers', 'places', 'useQueryPlanningOrderStatistics'];
      // eslint-disable-next-line no-void
      void queryClient.resetQueries({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        predicate: (query) => resetQueryKeys.includes(`${query.queryKey[0]}`),
      });
      resetQuery();
      dispatchIsLoading({ type: 'notLoading' });
    });
  }, [resetQuery]);

  const resetDrivers = useCallback(() => {
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(['useQueryPlanningsDrivers']);
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(['useQueryPlanningsDeliveries']);
  }, []);

  const mergeYoidumi = useCallback((deliveries: PlanningsDeliveryEntity[], responseData: PlanningsDeliveryEntity[]) => {
    const ids = deliveries.map((it) => it.id);
    const extraEntities = responseData.filter((it) => !ids.includes(it.id));
    return [...deliveries, ...extraEntities];
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void = useCallback(async (requestOrderIds) => {
    enqueueSnackbar('配送計画を削除します');

    dispatchIsLoading({
      type: 'loading'
    });

    try {
      await operationBulkResetting.mutateAsync(requestOrderIds, {
        onSuccess: (res: AxiosResponse<PlanningsDeliveryEntity[]>) => {
          // 宵積みがあるとリクエストに含まれないものも削除するので、戻り値を受け取り追加する。
          const merged = mergeYoidumi(deliveryEntities, res.data);

          const allocateHistoryEntity: AllocateHistoryEntity = {
            version: generateRandomString(20),
            companyId: licenseContext.config?.selected_company_id,
            actAt: new Date(),
            action: '割当部分解除',
            message: `${requestOrderIds.length.toLocaleString()}件の割当をすべて解除しました`,
            deliveryEntities: merged,
          };

          dispatchAllocateHistories({
            type: 'add',
            payload: allocateHistoryEntity
          });
        }
      });

      dispatchOrderIds({
        type: 'add',
        payload: requestOrderIds
      });
      resetQuery();

      enqueueSnackbar('配送計画を削除しました');
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as unknown as AxiosError<string>;
        enqueueSnackbar(axiosError.response.data);
      }
      reset();
      throw error;
    } finally {
      dispatchIsLoading({
        type: 'notLoading'
      });
    }
  }, [deliveryEntities, enqueueSnackbar, licenseContext.config?.selected_company_id, mergeYoidumi, reset, resetQuery]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const mutateDeleteShiftsOperations: (deliveryIds: number[]) => void = useCallback(async (deliveryIds) => {
    if (!window.confirm('選択した計画をリセットしますか？')) { return; }

    const requestOrderIds: number[] = [];
    selectedCycleIndexes.forEach((it) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[`${it.deliveryId}`];
      if (delivery) {
        it.cycleIndexes.forEach((idx) => {
          const cycle = delivery.cycles.find((cycl) => cycl.cycleIndex === idx);
          if (cycle) {
            requestOrderIds.push(...Array.from(new Set(cycle.allOperations().map((ops) => ops.orderId))));
          }
        });
      }
    });

    enqueueSnackbar(`${deliveryIds.length.toLocaleString()}件の配送計画を削除します`);

    dispatchIsLoading({
      type: 'loading'
    });

    try {
      await operationBulkResetting.mutateAsync(requestOrderIds, {
        onSuccess: (res: AxiosResponse<PlanningsDeliveryEntity[]>) => {
          // 宵積みがあるとリクエストに含まれないものも削除するので、戻り値を受け取り追加する。
          const merged = mergeYoidumi(deliveryEntities, res.data);

          const allocateHistoryEntity: AllocateHistoryEntity = {
            version: generateRandomString(20),
            companyId: licenseContext.config?.selected_company_id,
            actAt: new Date(),
            action: '割当全解除',
            message: `${deliveryIds.length.toLocaleString()}件の割当をすべて解除しました`,
            deliveryEntities: merged,
          };

          dispatchAllocateHistories({
            type: 'add',
            payload: allocateHistoryEntity
          });
        }
      });

      dispatchOrderIds({
        type: 'add',
        payload: requestOrderIds
      });

      const invalidateQueriesRequests = deliveryIds.map((id) => queryClient.invalidateQueries(['useQueryPlanningsDelivery', { id }]));

      await Promise.all(invalidateQueriesRequests);

      dispatchEditPlaces({ type: 'reset' });
      resetQuery();

      enqueueSnackbar(`${deliveryIds.length.toLocaleString()}件の配送計画を削除しました`);
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as unknown as AxiosError<string>;
        enqueueSnackbar(axiosError.response.data);
      }
      reset();
      throw error;
    } finally {
      dispatchIsLoading({
        type: 'notLoading'
      });
    }
  }, [deliveryEntities, enqueueSnackbar, licenseContext.config?.selected_company_id, mergeYoidumi, operationBulkResetting, planningsOperationDeliveryByDeliveryIdEntity, queryClient, reset, resetQuery, selectedCycleIndexes]);

  const { data: planningOrderStatisticsEntity } = useQueryPlanningOrderStatistics(startOn, endOn, selectedOrderIds);

  const [notAllocState, dispatchNotAllocReasonState] = useReducer(
    notAllocReasonStateReducer,
    { sessionId: null, notAllocOrderLength: 0, disabledOrders: [] }
  );

  const { data: notAllocReasonData, isLoading: notAllocReasonDataIsLoading } = useQueryPlanningsNotAllocReasons(notAllocState.sessionId);
  const [notAllocReasons, dispatchNotAllocReasons] = useReducer(notAllocReasonsReducer, null);

  const showNotAllocReasons = useCallback(() => {
    if (!notAllocState.sessionId) return;
    if (notAllocReasonDataIsLoading) return;
    if ([truckData, driverData, notAllocReasonData, deliveryData].some((maybe) => !maybe)) return;

    const truckOrder = truckData.reduce((map, truck, index) => { map[truck.id] = index; return map; }, {});
    const driverOrder = driverData.reduce((map, driver, index) => { map[driver.id] = index; return map; }, {});
    const notAllocDeliveryIds = notAllocReasonData.map((it) => it.delivery_id);
    const notAllocDeliveries = deliveryData.filter((it) => notAllocDeliveryIds.includes(it.id));
    const sorted = notAllocReasonData.sort((a, b) => {
      const deliveryA = notAllocDeliveries.find((it) => it.id === a.delivery_id);
      const deliveryB = notAllocDeliveries.find((it) => it.id === b.delivery_id);
      // 宵積みの場合に翌日の deliveryId が返ってくることがあるため、deliveryId がない場合は 0 として扱う
      if (deliveryA === undefined || deliveryB === undefined) return 0;

      let ret = truckOrder[deliveryA.truckId] - truckOrder[deliveryB.truckId];
      if (ret === 0) {
        ret = driverOrder[deliveryA.driverId] - driverOrder[deliveryB.driverId];
      }
      return ret;
    });
    if (notAllocState.disabledOrders.length > 0) {
      notAllocState.disabledOrders.forEach((it: number) => {
        sorted.push({
            order_id: it,
            delivery_id: 0,
            message: '有料道路を使用しないと到達できないルートがあります',
        });
      });
    }

    dispatchNotAllocReasons({
      type: 'set',
      payload: sorted
    });
  }, [deliveryData, driverData, notAllocReasonData, notAllocReasonDataIsLoading, notAllocState, truckData]);

  useEffect(() => {
    if (notAllocReasonDataIsLoading) return;

    showNotAllocReasons();
  }, [notAllocReasonDataIsLoading, showNotAllocReasons]);

  const handlePlanningResponse = useCallback((response: PlanningResponseEntity, message: string) => {
    resetQuery();

    enqueueSnackbar(message);

    const addOrderIds = response.delete_order_ids_from_operations ? [
      ...response.not_allocated_order_ids,
      ...response.delete_order_ids_from_operations,
    ] : response.not_allocated_order_ids;

    dispatchOrderIds({
      type: 'add',
      payload: addOrderIds
    });

    if (response.messages && response.messages.length > 0) {
      enqueueSnackbar(response.messages.join(', '));
    }
    if (response.not_allocated_order_ids && response.not_allocated_order_ids.length) {
      if (response.session_id) {
        dispatchNotAllocReasonState({
          type: 'set',
          payload: {
            sessionId: response.session_id,
            notAllocOrderLength: response.not_allocated_order_ids.length,
            disabledOrders: response.disabled_order_ids,
          }
        });
        enqueueSnackbar(`${response.not_allocated_order_ids.length.toLocaleString()}件が割り当たりませんでした。割当たらない理由を確認してください(最大100件まで)。`);
      } else {
        enqueueSnackbar(`${response.not_allocated_order_ids.length.toLocaleString()}件が割り当たりませんでした。`);
      }
    } else {
      dispatchNotAllocReasonState({
        type: 'set',
        payload: { sessionId: null, notAllocOrderLength: 0, disabledOrders: [] }
      });
    }
  }, [enqueueSnackbar, resetQuery]);

  const handleErrorResponse = useCallback((error: AxiosError<{
    message: string;
  }>) => {
    queryClient.clear();

    if (error.response?.status === 400 && error.response?.data?.message) {
      enqueueSnackbar(error.response.data.message);
    } else {
      enqueueSnackbar(fiveZeroZeroErrorMessage);
    }
  }, []);

  const requestScenarioPlanning = useCallback((scenarioId: number) => {
    scenarioPlanning.mutate({ scenarioId, startOn, endOn }, {
      onSuccess: (data: AxiosResponse<AsyncPlanningResponseEntity>) => {
        dispatchPlanning({
          type: 'resetRestrictedInsertPosition',
        });
        if (data.data.status === 'queued') {
          const entity: AsyncPlanningResponseEntity = data.data;
          setAsyncPlanningResponse(entity);
          enqueueSnackbar('自動配車を実行中です。完了までしばらくお待ち下さい。');
          refreshRunningEntities();
        } else {
          enqueueSnackbar('シナリオ実行に失敗しました');
        }
      },
      onError: (e: AxiosError<{ message: string; }>) => {
        handleErrorResponse(e);
      },
    });
  }, [endOn, enqueueSnackbar, handleErrorResponse, scenarioPlanning, startOn]);

  const requestSingleAlgorithmPlanning = useCallback((deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => {
    const delivery = deliveryData.find((it) => it.id === deliveryId);
    const { shiftId } = delivery;

    const plan: PlanningEntity = {
      startOn,
      endOn,
      lockType: 'sequence',
      isRespectRecent: false,
      excludeToll: false,
      respectType: 'day_of_week',
      respectDaysOfWeek: [],
      respectOn: '',
      isTimeTableBackward: false,
      includeFutureDeliveries: false,
      activateConstraintLoadAfterNotCleared: false,
      orderOperationIdsForSort,
      deleteOrderIdsFromOperations,
      selectedRelaxedRules: [],
      priorityLargeTrucks: 0.5,
      expandLoadStartTime: 0,
      expandLoadEndTime: 0,
      expandUnloadStartTime: 0,
      expandUnloadEndTime: 0,
      expandMaxVolumeRate: 0.0,
      expandMaxLoadCapacityRate: 0.0,
      requestAsync: false,
      mlSourceType: -1,
      balancedLoading: false,
      orderOperationCycleIndexes,
      autoSplitOrders: false,
      dontExchangePreroute: true,
      concurrentAllOrNothing: licenseContext.config.concurrent_all_or_nothing,
      mlPlanning: true,
    };

    const truck = truckEntities.find(({ id }) => id === delivery.truckId);
    const driver = driverEntities.find(({ id }) => id === delivery.driverId);
    const message = `${driver.name}(${truck.licensePlateValue})の自動配車を編集しました`;

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '編集',
      message,
      deliveryEntities,
    };

    let async = false;
    normalPlanning.mutate(
      {
        shiftIds: [shiftId],
        orderIds: [],
        planning: plan,
        latestAlgorithmRequestVersion,
        requestAsync: true,
        selectedCycleIndexes: [],
        deliveryOperations: [],
        planningSetting: null,
      },
      {
        onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
          dispatchPlanning({
            type: 'resetRestrictedInsertPosition',
          });
          dispatchAllocateHistories({
            type: 'add',
            payload: allocateHistoryEntity
          });
          if (data.data.status === 'queued') {
            async = true;
            const entity: AsyncPlanningResponseEntity = data.data;
            setAsyncPlanningResponse(entity);
            enqueueSnackbar('自動配車を実行中です。完了までしばらくお待ち下さい。');
          } else {
            handlePlanningResponse(data.data, '修正が完了しました');
          }
        },
        onError: (e: AxiosError<{ message: string; }>) => {
          handleErrorResponse(e);
        },
        onSettled: () => {
          if (async) return;

          dispatchIsLoading({
            type: 'notLoading'
          });
          setCalculating(false);
        }
      }
    );
  }, [deliveryData, startOn, endOn, truckEntities, driverEntities, licenseContext.config?.selected_company_id, deliveryEntities, latestAlgorithmRequestVersion, enqueueSnackbar, handlePlanningResponse, handleErrorResponse, setCalculating]);

  const rollbackRequest = useCallback(() => {
    if (!currentHistoryVersion) return;

    const deliveryIds: number[] = deliveryEntities.map(({ id }) => id);
    const current = allocateHistories.find(({ version }) => version === currentHistoryVersion);

    if (![deliveryIds.length, current].every((maybe) => !!maybe)) return;

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '取消',
      message: `${datetimeDecorator.toHourMinuteSeconds(current.actAt)}までまで元に戻しました`,
      deliveryEntities: deliveryData,
    };

    dispatchAllocateHistories({
      type: 'add',
      payload: allocateHistoryEntity
    });

    enqueueSnackbar('操作を取り消します');

    setCalculating(true);
    dispatchIsLoading({ type: 'loading' });

    const orderOperations: PlanningsOperationEntityWithStatsEntity[] = current.deliveryEntities.flatMap(({ operations: ops }) => ops as unknown as PlanningsOperationEntityWithStatsEntity);
    orderOperations.forEach((it) => delete it.timeWindow);

    const arrivalOperations: BaseOperationEntity[] = current.deliveryEntities
      .flatMap(({ arrivalOperation }) => arrivalOperation)
      .filter((maybe) => maybe);
    const departureOperations: BaseOperationEntity[] = current.deliveryEntities
      .flatMap(({ departureOperation }) => departureOperation)
      .filter((maybe) => maybe);
    const distanceMmBurdenPerShippersEntities: distanceMmBurdenPerShippersEntity[] = current.deliveryEntities
      .flatMap(({ distanceMmBurdenPerShippers }) => distanceMmBurdenPerShippers)
      .filter((maybe) => maybe);

    rollback.mutate(
      {
        deliveryIds,
        orderOperations,
        arrivalOperations,
        departureOperations,
        distanceMmBurdenPerShippersEntities,
        latestAlgorithmRequestVersion,
        startOn,
        endOn,
      },
      {
        onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
          enqueueSnackbar('操作を取り消しました');
          queryClient.clear();
        },
        onError: (e: AxiosError<{ message: string; }>) => {
          handleErrorResponse(e);
        },
        onSettled: () => {
          dispatchIsLoading({
            type: 'notLoading'
          });
          setCalculating(false);
          dispatchCurrentHistoryVersion({ type: 'reset' });
          queryClient.clear();
        }
      }
    );
  }, [currentHistoryVersion, deliveryEntities, allocateHistories, licenseContext.config?.selected_company_id, deliveryData, setCalculating, rollback, latestAlgorithmRequestVersion, startOn, endOn, handleErrorResponse]);

  const [planning, dispatchPlanning] = useReducer(planningReducer, planningInitialState);
  const relaxedRules: RelaxedRuleVo[] = useMemo(() => (
    [
      '労働時間',
      '指定時間',
      '重量',
      '体積',
      '指定車両タイプ',
      '指定車種',
      '荷台高さ',
      '荷台幅',
      '荷台長さ',
      '床仕様',
      '装置',
      'ピストン回数',
      '1回転あたりの降地',
      '案件数上限',
      '積み合わせ',
      '指定トラック',
      'NGドライバー',
      'トラックの上限ピストン回数',
      '１回転あたりの上限納品先件数',
      'エリア配車',
    ]
  ), []);

  const emptyArray = useMemo(() => [], []);
  const selectedRelaxedRules = useMemo(() => (
    planning.selectedRelaxedRules || emptyArray as RelaxedRuleVo[]
  ), [emptyArray, planning.selectedRelaxedRules]);

  const setSelectedRelaxedRules = useCallback((rules: RelaxedRuleVo[]) => {
    dispatchPlanning({
      type: 'updateSelectedRelaxedRules',
      payload: rules
    });
  }, []);

  const planningSettings = useCallback(() => {
    const json = localStorage.getItem('PlanningPresenterSettings');
    return JSON.parse(json) as unknown as PlanningConditions;
  }, []);

  const [allDeliverySelected, setAllDeliverySelected] = useState(false);

  const planningRequest: (requestDeliveryIds: number[], requestOrderIds: number[]) => void = useCallback((
    requestDeliveryIds,
    requestOrderIds
  ) => {
    dispatchNotAllocReasons({ type: 'reset' });
    dispatchNotAllocReasonState({
      type: 'set',
      payload: { sessionId: null, notAllocOrderLength: 0, disabledOrders: [] }
    });

    const shiftIds = requestDeliveryIds
        .map((it) => {
          const entity = deliveryEntities.find((e) => e.id === it);
          return entity ? entity.shiftId : null;
        });

    if (shiftIds.length === 0) {
      enqueueSnackbar('配車可能なシフトがありません');
      return;
    }

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '自動配車',
      message: '自動配車を実行しました',
      deliveryEntities,
    };

    dispatchAllocateHistories({
      type: 'add',
      payload: allocateHistoryEntity
    });

    enqueueSnackbar('自動配車を開始します');

    setCalculating(true);

    dispatchIsLoading({
      type: 'loading'
    });

    dispatchSelectedOrderIds({ type: 'reset' });

    const deliveryOperations = requestDeliveryIds.map((deliveryId) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[deliveryId];
      delivery.cycles.forEach((it) => {
        it.allOperations().forEach((ops) => {
          ops.cycle = it.cycleIndex;
        });
      });
      return {
        deliveryId,
        operations: delivery.allOperations().map((it) => ({
          id: it.id,
          cycle: it.cycle,
          orderId: it.orderId,
          action: it.action,
        }))
      };
    });

    const selectedCycleIndexesOnlyAlloced = selectedCycleIndexes.map((it) => {
      const delivery = planningsOperationDeliveryByDeliveryIdEntity[it.deliveryId];
      if (delivery) {
        const selectedCycles = delivery.cycles.filter((c) => it.cycleIndexes.includes(c.cycleIndex));
        if (selectedCycles.every((c) => c.empty)) {
          return {
            ...it,
            cycleIndexes: [it.cycleIndexes[0]], // すべて未割り当てなら最初の回転だけにする
          };
        }
        return {
          ...it,
          cycleIndexes: selectedCycles.filter((c) => !c.empty).map((c) => c.cycleIndex), // 割り当て済みが含まれる場合は割り当て済みだけにする
        };
      }
      return it;
    });
    const deliveryConditions = {
      truckDisplayStatus,
      selectedGroupIds: selectedGroupEntities.map((it) => it.id),
      selectedGroups: selectedGroupEntities,
      allDeliverySelected,
      selectedCycleIndexes,
      selectedDeliveries: selectedCycleIndexes.map((it) => {
        const { deliveryId } = it;
        const delivery = deliveryEntities.find((deli) => deli.id === deliveryId);
        if (!delivery) return null;

        const truck = truckEntities.find((t) => t.id === delivery.truckId);
        const driver = driverEntities.find((d) => d.id === delivery.driverId);
        return {
          deliveryId,
          truckId: truck.id,
          licensePlateValue: truck.licensePlateValue,
          driverId: driver.id,
          driverName: driver.name,
        };
      })
    };
    const orderConditions = {
      orderSearchKw,
      orderSearchConditions: currentlyOrderSearching ? orderSearchConditions : [],
    };
    const planningConditions = planningSettings();
    const planningSetting: PlanningSettingRequest = {
      deliveryConditions,
      orderConditions,
      planningConditions,
    };

    let async = false;
    normalPlanning.mutate(
      {
        shiftIds,
        orderIds: requestOrderIds,
        planning,
        latestAlgorithmRequestVersion,
        requestAsync: true,
        selectedCycleIndexes: selectedCycleIndexesOnlyAlloced,
        deliveryOperations,
        planningSetting,
      },
      {
        onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
          dispatchPlanning({
            type: 'resetRestrictedInsertPosition',
          });
          dispatchOrderIds({
            type: 'remove',
            payload: requestOrderIds
          });

          if (data.data.status === 'queued') {
            async = true;
            const entity: AsyncPlanningResponseEntity = data.data;
            setAsyncPlanningResponse(entity);
            selectedOrderIds.forEach((it) => dispatchSelectedOrderIds({ type: 'add', payload: it }));
            enqueueSnackbar('自動配車を実行中です。完了までしばらくお待ち下さい。');
            refreshRunningEntities();
          } else {
            handlePlanningResponse(data.data, '自動配車が完了しました');
          }
        },
        onError: (e: AxiosError<{ message: string }>) => {
          handleErrorResponse(e);
        },
        onSettled: () => {
          if (async) return;

          dispatchIsLoading({
            type: 'notLoading'
          });
          setCalculating(false);
        }
      }
    );
  }, [licenseContext.config?.selected_company_id, deliveryEntities, enqueueSnackbar, setCalculating, selectedCycleIndexes, truckDisplayStatus, selectedGroupEntities, allDeliverySelected, orderSearchKw, currentlyOrderSearching, orderSearchConditions, planningSettings, normalPlanning, planning, latestAlgorithmRequestVersion, planningsOperationDeliveryByDeliveryIdEntity, selectedOrderIds, refreshRunningEntities, handlePlanningResponse, handleErrorResponse, driverEntities, truckEntities]);

  useEffect(() => {
    if (!(planningResultData || planningResultError)) return;

    if (planningResultData) {
      handlePlanningResponse(planningResultData, '自動配車が完了しました');
    } else if (planningResultError) {
      handleErrorResponse(planningResultError);
    }
    setAsyncPlanningResponse(null);
    // resetEditPositions();
    refreshRunningEntities();
  }, [handleErrorResponse, handlePlanningResponse, planningResultData, planningResultError, refreshRunningEntities]);

  useEffect(() => {
    const loading = asyncPlanningResponse != null;
    dispatchIsLoading({
      type: loading ? 'loading' : 'notLoading'
    });
    setCalculating(loading);
  }, [asyncPlanningResponse, setCalculating]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const transferRequest: (requestDeliveryIds: number[], requestOrderIds: number[]) => void = useCallback(async (requestDeliveryIds, requestOrderIds) => {
    if (!requestDeliveryIds) return;
    if (!requestOrderIds) return;

    const transferToDelivery = deliveryEntities
      .find(({ id }) => id === requestDeliveryIds[0]);
    const transferToShiftId = transferToDelivery.shiftId;
    const transferFromDelivery = deliveryEntities
      .find((delivery) => requestOrderIds.some((orderId) => delivery.operations.map((it) => it.orderId).includes(orderId)));
    const transferFromShiftIds = [
      transferFromDelivery.shiftId
    ];

    if (!transferToShiftId) return;
    if (!transferFromShiftIds.length) return;

    const trucks = [transferToDelivery, transferFromDelivery]
      .map(({ truckId }) => truckEntities.find(({ id }) => id === truckId));
    const message = `${trucks.map(({ licensePlateValue }) => licensePlateValue).join('から')}へ${requestOrderIds.length.toLocaleString()}件の配送計画を移管しました`;

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '割当移管',
      message,
      deliveryEntities,
    };

    dispatchAllocateHistories({
      type: 'add',
      payload: allocateHistoryEntity
    });

    enqueueSnackbar(`${requestOrderIds.length.toLocaleString()}件の配送計画を移動します`);

    setCalculating(true);

    dispatchIsLoading({
      type: 'loading'
    });

    await operationBulkResetting.mutateAsync(requestOrderIds);

    let async = false;
    transferPlanning.mutate({
      orderIds: requestOrderIds,
      transferToShiftId,
      planning,
      latestAlgorithmRequestVersion,
      transferFromShiftId: transferFromDelivery.shiftId,
    }, {
      onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
        if (data.data.status === 'queued') {
          async = true;
          const entity: AsyncPlanningResponseEntity = data.data;
          setAsyncPlanningResponse(entity);
          enqueueSnackbar('自動配車を実行中です。完了までしばらくお待ち下さい。');
        } else {
          handlePlanningResponse(data.data, `${requestOrderIds.length.toLocaleString()}件の配送計画を移動しました`);
        }
      },
      onError: (e: AxiosError<{ message: string; }>) => {
        handleErrorResponse(e);
      },
      onSettled: () => {
        if (async) return;

        dispatchIsLoading({
          type: 'notLoading'
        });
        setCalculating(false);
      }
    });
  }, [deliveryEntities, handleErrorResponse, handlePlanningResponse, latestAlgorithmRequestVersion, licenseContext.config?.selected_company_id, operationBulkResetting, planning, setCalculating, transferPlanning, truckEntities]);

  const swapRequest: (swapFromDeliveryId: number, swapToDeliveryId: number) => void = useCallback((swapFromDeliveryId, swapToDeliveryId) => {
    const requestDeliveryIds: [number, number] = [swapFromDeliveryId, swapToDeliveryId];

    const deliveries = [swapFromDeliveryId, swapToDeliveryId].map((it) => deliveryEntities.find(({ id }) => id === it));
    const haveYoizumi = deliveries.map((it) => !!(it.operations.length % 2)).some((bool) => bool);

    if (haveYoizumi) {
      enqueueSnackbar('宵積みが存在しているため、配送計画の入れ替えはできません');
      return;
    }
    if (!window.confirm('選択した配送計画を入れ替えますか？')) { return; }

    const trucks = deliveries
      .map(({ truckId }) => truckEntities.find(({ id }) => id === truckId));
    const message = `${trucks.map(({ licensePlateValue }) => licensePlateValue).join('と')}の配送計画を入れ替えました`;

    const allocateHistoryEntity: AllocateHistoryEntity = {
      version: generateRandomString(20),
      companyId: licenseContext.config?.selected_company_id,
      actAt: new Date(),
      action: '割当交換',
      message,
      deliveryEntities,
    };

    dispatchAllocateHistories({
      type: 'add',
      payload: allocateHistoryEntity
    });

    enqueueSnackbar('配送計画を入れ替えします');
    dispatchIsLoading({
      type: 'loading'
    });
    setCalculating(true);

    let async = false;
    swapPlanning.mutate({
        deliveryIds: requestDeliveryIds,
        latestAlgorithmRequestVersion,
        startOn,
        endOn,
    }, {
      onSuccess: (data: AxiosResponse<PlanningResponseEntity>) => {
        if (data.data.status === 'queued') {
          async = true;
          const entity: AsyncPlanningResponseEntity = data.data;
          setAsyncPlanningResponse(entity);
          enqueueSnackbar('自動配車を実行中です。完了までしばらくお待ち下さい。');
        } else {
          resetQuery();
          enqueueSnackbar('配送計画を入れ替えました');

          if (data.data.not_allocated_order_ids.length) {
            dispatchOrderIds({
              type: 'add',
              payload: data.data.not_allocated_order_ids
            });

            enqueueSnackbar(`${data.data.not_allocated_order_ids.length.toLocaleString()}件が割り当たりません`);
          }
        }
      },
      onError: (e: AxiosError<{ message: string; }>) => {
        handleErrorResponse(e);
      },
      onSettled: () => {
        if (async) return;
        dispatchIsLoading({
          type: 'notLoading'
        });
        setCalculating(false);
      }
    });
  }, [licenseContext.config?.selected_company_id, deliveryEntities, enqueueSnackbar, setCalculating, latestAlgorithmRequestVersion, startOn, endOn, truckEntities, resetQuery, handleErrorResponse]);

  const setPriorityLargeTrucks = useCallback((priority: number) => {
    dispatchPlanning({
      type: 'updatePriorityLargeTrucks',
      payload: priority
    });
  }, []);
  const updateBalancedLoading = useCallback((balancedLoading: boolean) => {
    dispatchPlanning({
      type: 'updateBalancedLoading',
      payload: balancedLoading
    });
  }, []);
  const updateIsRespectRecent = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateIsRespectRecent',
      payload: bool
    });
  }, []);
  const updateExcludeToll = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateExcludeToll',
      payload: bool
    });
  }, []);
  const updateRespectType = useCallback((respectType: RespectType) => {
    dispatchPlanning({
      type: 'updateRespectType',
      payload: respectType
    });
  }, []);
  const updateRespectDaysOfWeek = useCallback((days: WeekDayEn[]) => {
    dispatchPlanning({
      type: 'updateRespectDaysOfWeek',
      payload: days
    });
  }, []);
  const updateRespectOn = useCallback((payload: string) => {
    dispatchPlanning({
      type: 'updateRespectOn',
      payload
    });
  }, []);
  const updateIsTimeTableBackward = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateIsTimeTableBackward',
      payload: bool
    });
  }, []);
  const updateIncludeFutureDeliveries = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateIncludeFutureDeliveries',
      payload: bool
    });
  }, []);
  const updateAutoSplitOrders = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateAutoSplitOrders',
      payload: bool
    });
  }, []);
  const updateDontExchangePreroute = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateDontExchangePreroute',
      payload: bool,
    });
  }, []);
  const updateConcurrentAllOrNothing = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateConcurrentAllOrNothing',
      payload: bool,
    });
  }, []);
  const updateMLPlanning = useCallback((bool: boolean) => {
    dispatchPlanning({
      type: 'updateMLPlanning',
      payload: bool,
    });
  }, []);
  const updateExpandLoadStartMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandLoadStartTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandLoadEndMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandLoadEndTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandUnloadStartMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandUnloadStartTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandUnloadEndMinutes = useCallback((minutes: number) => {
    dispatchPlanning({
      type: 'updateExpandUnloadEndTime',
      payload: minutes * 60
    });
  }, []);
  const updateExpandMaxVolumeRate = useCallback((rate: number) => {
    dispatchPlanning({
      type: 'updateExpandMaxVolumeRate',
      payload: rate / 100.0
    });
  }, []);
  const updateExpandMaxLoadCapacityRate = useCallback((rate: number) => {
    dispatchPlanning({
      type: 'updateExpandMaxLoadCapacityRate',
      payload: rate / 100.0
    });
  }, []);
  const updateMLSourceType = useCallback((type: number) => {
    dispatchPlanning({
      type: 'updateMLSourceType',
      payload: type,
    });
  }, []);

  const [transfer, dispatchTransfer] = useReducer(transferRequestReducer, undefined);
  const setTransfer = useCallback(
    (entity: TransferRequestEntity) => {
      dispatchTransfer({
        type: 'set',
        payload: entity
      });
    },
    [],
  );
  const resetTransfer = useCallback(
    () => {
      dispatchTransfer({
        type: 'reset'
      });
    },
    [],
  );

  useEffect(() => {
    if (!orderEntitiesInfiniteQuery.data) return;

    dispatchOrderIds({
      type: 'set',
      payload: orderEntitiesInfiniteQuery.data.pages.flatMap((page) => page.ids)
    });
    const entities = orderEntitiesInfiniteQuery.data.pages.flatMap((page) => page.data);
    const map = new Map<number, OrderEntity>();
    entities.forEach((it) => {
      map.set(it.id, it);
    });
    dispatchOrderEntityMap({
      type: 'set',
      payload: map
    });
    const ids = entities.map((it) => it.id);
    selectedOrderIds.forEach((it) => {
      if (!ids.includes(it)) {
        dispatchSelectedOrderIds({
          type: 'remove',
          payload: it,
        });
      }
    });
  }, [orderEntitiesInfiniteQuery.data, selectedOrderIds]);

  const [importFile, dispatchImportFile] = useReducer(importFileReducer, undefined);
  const setImportFile = useCallback((file: File) => {
    dispatchImportFile({
      type: 'set',
      payload: file
    });
  }, []);

  const [importForUpdateFile, dispatchImportForUpdateFile] = useReducer(importForUpdateFileReducer, undefined);
  const setImportForUpdateFile = useCallback((file: File) => {
    dispatchImportForUpdateFile({
      type: 'set',
      payload: file
    });
  }, []);

  const { cloneLastWeekShifts } = useMutationShiftClone();

  const mutateCloneLastWeekShifts: (date: string) => void = useCallback((date: string) => {
    const startAt = new Date(startOn);
    const weekStartAt = format(startOfWeek(startAt), 'MM/dd', { timeZone: 'Asia/Tokyo' });
    const weekEndAt = format(endOfWeek(startAt), 'MM/dd', { timeZone: 'Asia/Tokyo' });

    const message = `・${weekStartAt} 〜 ${weekEndAt}間で作成済みのシフトは上書きされます。
・${weekStartAt} 〜 ${weekEndAt}間で作成済みの配車計画は削除されます。

よろしいですか？`;

    // eslint-disable-next-line no-restricted-globals
    if (!confirm(message)) return;

    dispatchIsLoading({
      type: 'loading'
    });

    cloneLastWeekShifts.mutate(date, {
      onSuccess: () => {
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['driverShifts']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['shifts']);
      },
      onError: (e: AxiosError<{ message: string; }>) => {
        handleErrorResponse(e);
      },
      onSettled: () => {
        dispatchIsLoading({
          type: 'notLoading'
        });
      }
    });
  }, [handleErrorResponse, startOn]);

  const { operationSendMail } = useMutationPlanningOperations();

  const sendOperationMail = useCallback(() => {
    operationSendMail.mutate({ startOn, endOn, shiftTruckIds: selectedCycleIndexes.map((it) => it.deliveryId) }, {
      onSuccess: () => { enqueueSnackbar('配送指示メールを送信しました'); },
      onError: (e: AxiosError<{ message: string; }>) => {
        const errMsg = e.response.data.message;
        enqueueSnackbar(`配送指示メールの送信がエラーになりました。${errMsg}`);
      }
    });
  }, [endOn, enqueueSnackbar, operationSendMail, selectedCycleIndexes, startOn]);

  const refreshOperationStatuses = useCallback(() => {
    resetQuery();
    // eslint-disable-next-line no-void
    void queryClient.invalidateQueries(['lastOperationStatusUpdated']);
  }, [queryClient, resetQuery]);

  useEffect(() => {
    if ([orderEntitiesInfiniteQuery.isInitialLoading, trucksIsLoading, driversIsLoading, deliveriesIsLoading].some((bool) => bool)) {
      dispatchIsLoading({ type: 'loading' });
    } else {
      dispatchIsLoading({ type: 'notLoading' });
    }
  }, [orderEntitiesInfiniteQuery.isInitialLoading, trucksIsLoading, driversIsLoading, deliveriesIsLoading]);

  useEffect(() => {
    if (!allocateHistories) return;
    if (!allocateHistories.length) return;

    setLatestAllocateHistory(
      allocateHistories[0]
    );
  }, [allocateHistories]);

  useEffect(() => {
    if (!importFile) return;

    const requestPath = '/api/v2/orders/import';

    const data = new FormData();
    data.append('file', importFile);

    dispatchIsLoading({ type: 'loading' });
    enqueueSnackbar('インポートを開始します');

    axios
      .post(requestPath, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(
        (response: AxiosResponse<{ severity: string; message: string }>) => {
          enqueueSnackbar(response.data.message);
        }
      )
      .catch((e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.response.status === 400) {
          enqueueSnackbar(
            '取り込めない注文がありました、データをご確認ください。'
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
          const blob = new Blob([e.response.data], {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            type: e.response.data.type
          });
          saveAs(blob, 'errors.txt');
        } else {
          enqueueSnackbar(fiveZeroZeroErrorMessage);
        }
      })
      .finally(() => {
        reset();
        dispatchIsLoading({
          type: 'notLoading'
        });
      });
  }, [importFile]);

  useEffect(() => {
    if (!importForUpdateFile) return;

    const requestPath = '/api/v3/orders/import_for_update';

    const data = new FormData();
    data.append('file', importForUpdateFile);

    dispatchIsLoading({ type: 'loading' });
    enqueueSnackbar('インポートを開始します');

    axios.post(requestPath, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(
      (response: AxiosResponse<{ severity: string; message: string }>) => {
        enqueueSnackbar(response.data.message);
      }
    ).catch((e) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (e.response.status === 400) {
        enqueueSnackbar(
          '取り込めない注文がありました、データをご確認ください。'
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        const blob = new Blob([e.response.data], {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          type: e.response.data.type
        });
        saveAs(blob, 'errors.txt');
      } else {
        enqueueSnackbar(fiveZeroZeroErrorMessage);
      }
    })
    .finally(() => {
      reset();
      setImportForUpdateFile(undefined);
      dispatchIsLoading({ type: 'notLoading' });
    });
  }, [enqueueSnackbar, importForUpdateFile, reset, setImportForUpdateFile]);

  useEffect(() => {
    dispatchTruckEntities({
      type: 'set',
      payload: truckData || []
    });
  }, [truckData]);

  useEffect(() => {
    dispatchDriverEntities({
      type: 'set',
      payload: driverData || []
    });
  }, [driverData]);

  useEffect(() => {
    dispatchDeliveryEntities({
      type: 'set',
      payload: deliveryData || []
    });
  }, [deliveryData]);

  useEffect(() => {
    if (!deliveryEntities) {
      dispatchOperationEntities({ type: 'reset' });
    } else {
      dispatchOperationEntities({
        type: 'set',
        payload: deliveryEntities.flatMap(({ operations }) => operations)
      });
    }
  }, [deliveryEntities]);

  useEffect(() => {
    if (!deliveryData) return;

    const deliveryHasOrderIds: number[] = arrayUtil
      .uniq<number>(
        deliveryData
          .flatMap(({ operations }) => operations
            .map(({ orderId }) => orderId))
      );

    dispatchOrderIds({
      type: 'remove',
      payload: deliveryHasOrderIds
    });

    deliveryHasOrderIds
      .map((it) => {
        dispatchSelectedOrderIds({
          type: 'remove',
          payload: it
        });

        return it;
      });
  }, [deliveryData]);

  useEffect(() => {
    dispatchLastOperationStatusUpdated({
      type: 'set',
      payload: lastOperationStatusUpdatedData
    });
  }, [lastOperationStatusUpdatedData]);

  useEffect(() => {
    dispatchLatestAlgorithmRequestVersion({
      type: 'set',
      payload: latestAlgorithmRequestVersionData
    });
  }, [latestAlgorithmRequestVersionData]);

  useEffect(() => {
    dispatchPlanning({
      type: 'updateActivateConstraintLoadAfterNotCleared',
      payload: activateConstraintLoadAfterNotCleared,
    });
  }, [activateConstraintLoadAfterNotCleared]);

  useEffect(() => {
    localStorage.setItem('planningOn', startOn);
  }, [startOn]);

  return (
    <Presenter
      planningRequest={planningRequest}
      startOn={startOn}
      endOn={endOn}
      orderIds={orderIds}
      selectedOrderIds={selectedOrderIds}
      addSelectedOrderId={addSelectedOrderId}
      removeSelectedOrderId={removeSelectedOrderId}
      unit={unit}
      isLoading={isLoading}
      updateBalancedLoading={updateBalancedLoading}
      updateIsRespectRecent={updateIsRespectRecent}
      updateExcludeToll={updateExcludeToll}
      updateRespectType={updateRespectType}
      updateRespectDaysOfWeek={updateRespectDaysOfWeek}
      updateRespectOn={updateRespectOn}
      mutateDeleteShiftsOperations={mutateDeleteShiftsOperations}
      mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
      transferRequest={transferRequest}
      transfer={transfer}
      setTransfer={setTransfer}
      resetTransfer={resetTransfer}
      mutateDeleteOrder={mutateDeleteOrder}
      mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
      mutateCloneOrder={mutateCloneOrder}
      setImportFile={setImportFile}
      setImportForUpdateFile={setImportForUpdateFile}
      mutateCloneLastWeekShifts={mutateCloneLastWeekShifts}
      displayOrderId={displayOrderId}
      resetDisplayOrderId={resetDisplayOrderId}
      updateDisplayOrderId={updateDisplayOrderId}
      planningMapUnallocatedOrderPositionEntities={planningMapUnallocatedOrderPositionEntities}
      garageData={garageData}
      truckEntities={truckEntities}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
      operationEntities={operationEntities}
      orderEntityMap={orderEntityMap}
      swapRequest={swapRequest}
      planningOrderStatisticsEntity={planningOrderStatisticsEntity}
      groupEntities={groupEntities}
      selectedGroupEntities={selectedGroupEntities}
      updateSelectedGroupEntities={updateSelectedGroupEntities}
      planningsMapGaragePositionEntities={planningsMapGaragePositionEntities}
      planningsMapOperationPositionEntities={planningsMapOperationPositionEntities}
      planningsOperationEntitiesWithStatsByDeliveryIdEntity={planningsOperationEntitiesWithStatsByDeliveryIdEntity}
      calculating={calculating}
      updateIsTimeTableBackward={updateIsTimeTableBackward}
      driverCostYenPerHours={driverCostYenPerHours}
      truckFuelCostYenPerKm={truckFuelCostYenPerKm}
      truckInsuranceFeeYenPerDay={truckInsuranceFeeYenPerDay}
      truckRepairCostYenPerDay={truckRepairCostYenPerDay}
      truckExpresswayFeeYenPerShift={truckExpresswayFeeYenPerShift}
      sendOperationMail={sendOperationMail}
      updateIncludeFutureDeliveries={updateIncludeFutureDeliveries}
      updateAutoSplitOrders={updateAutoSplitOrders}
      updateDontExchangePreroute={updateDontExchangePreroute}
      updateConcurrentAllOrNothing={updateConcurrentAllOrNothing}
      updateMLPlanning={updateMLPlanning}
      refreshOperationStatuses={refreshOperationStatuses}
      lastOperationStatusUpdated={lastOperationStatusUpdated}
      resetDrivers={resetDrivers}
      flyToPosition={flyToPosition}
      resetFlyToPosition={resetFlyToPosition}
      fitBoundsPositions={fitBoundsPositions}
      resetFitBoundsPositions={resetFitBoundsPositions}
      allocateHistories={allocateHistories}
      currentHistoryVersion={currentHistoryVersion}
      setCurrentHistoryVersion={setCurrentHistoryVersion}
      resetCurrentHistoryVersion={resetCurrentHistoryVersion}
      resetAllocateHistories={resetAllocateHistories}
      rollbackRequest={rollbackRequest}
      customInputFields={customInputFields}
      relaxedRules={relaxedRules}
      selectedRelaxedRules={selectedRelaxedRules}
      setSelectedRelaxedRules={setSelectedRelaxedRules}
      setPriorityLargeTrucks={setPriorityLargeTrucks}
      priorityLargeTrucks={planning.priorityLargeTrucks}
      notAllocReasons={notAllocReasons}
      updateExpandLoadStartMinutes={updateExpandLoadStartMinutes}
      updateExpandLoadEndMinutes={updateExpandLoadEndMinutes}
      updateExpandUnloadStartMinutes={updateExpandUnloadStartMinutes}
      updateExpandUnloadEndMinutes={updateExpandUnloadEndMinutes}
      updateExpandMaxVolumeRate={updateExpandMaxVolumeRate}
      updateExpandMaxLoadCapacityRate={updateExpandMaxLoadCapacityRate}
      pastOperationsData={pastOperationsData}
      latestAllocateHistory={latestAllocateHistory}
      directionSettingDialogIsOpen={directionSettingDialogIsOpen}
      updateDirectionSettingDialogIsOpen={updateDirectionSettingDialogIsOpen}
      planningRunningEntities={planningRunningEntities}
      refreshRunningEntities={refreshRunningEntities}
      cancelRunningPlan={cancelRunningPlan}
      updateMLSourceType={updateMLSourceType}
      requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
      selectedCycleIndexes={selectedCycleIndexes}
      updateSelectedCycleIndexes={updateSelectedCycleIndexes}
      planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
      editPlaces={editPlaces}
      resetEditPlaces={resetEditPlaces}
      updateEditPlaces={updateEditPlaces}
      addEmptyCycle={addEmptyCycle}
      removeEmptyCycle={removeEmptyCycle}
      truckDisplayStatus={truckDisplayStatus}
      setTruckDisplayStatus={setTruckDisplayStatus}
      orderSearchKw={orderSearchKw}
      setOrderSearchKw={setOrderSearchKw}
      currentlyOrderSearching={currentlyOrderSearching}
      setCurrentlyOrderSearching={setCurrentlyOrderSearching}
      setOrderSearchConditions={setOrderSearchConditions}
      setAllDeliverySelected={setAllDeliverySelected}
      requestScenarioPlanning={requestScenarioPlanning}
      asyncPlanningResponse={asyncPlanningResponse}
      mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
    />
  );
});

export default Component;
